.css-1v4kpr5 {
    height: 330px !important;
    position: relative;
    margin-bottom: 20px;
}
#grid2{
    margin-top: 20px;
}
.css-8akur5-MuiTypography-root {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 15px !important;
    line-height: 0px !important;
    letter-spacing: -0.06px;
    color: #172b4d;
}
.css-1xeod2-MuiLinearProgress-bar1 {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    -webkit-transition: -webkit-transform .4s linear;
    transition: transform .4s linear;
    transform-origin: left;
    background-color: #56d27f ! important;
}
/* #assessment-table {
    padding: 10px;
    min-height: 830px;
} */
#assesments-card{
    /* height:100%; */
    background-color: white;
}