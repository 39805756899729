.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.dots li {
  font-size: 25px;
  /* padding-left:20px; */
  list-style: none;
  /* margin-top: -5px; */
  /* text-align: center; */
}
.dashboard {
  margin-top: -30px;
}

.dots li::before {
  content: '\2022';
  font-weight: bold;
  display: inline-block;
  width: 50px;
  margin-left: -1em;
  font-size: 28px;
}
.dot-green::before {
  color: #9bdd9b;
  margin-right: -26px !important;
}

.dot-red::before {
  color: #f99564;
  margin-right: -26px !important;
}
.dot-orange::before {
  color: #f4cc69;
  margin-right: -26px !important;
}
.dot-grey::before {
  color: #5d5d5d;
  margin-right: -26px !important;
}
.dot-green-cap::before {
  color: #0ba360;
  margin-right: -26px !important;
}

.dot-red-cap::before {
  color: #f96e6e;
  margin-right: -26px !important;
}
.dot-orange-cap::before {
  color: #fda232;
  margin-right: -26px !important;
}

.select {
  width: 200px;
  font-size: 30px;
  margin-top: -20px;
}
.demo-simple-select-outlined-label {
  font-size: 25px;
}
.pull-right {
  float: right;
  position: relative;
  z-index: 1000;
}
.mdb-datatable table td:nth-child(4) {
  border-left: 1px solid #dee2e6;
}
.mdb-datatable table th:nth-child(4) {
  border-left: 1px solid #dee2e6;
}
.mdb-datatable table th:nth-child(5) {
  border-left: 1px solid #dee2e6 !important;
}
#notice-add a {
  color: black;
  float: right;
  padding: 15px;
}
#notice-add h5 {
  font-size: 15px;
}
/* .css-weuz2y-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 11.5px 14px !important;   
    
} */
.select {
  margin-top: -17px !important;
  margin-bottom: 10px !important;
  /* margin-right: 10px; */
}

.css-1l3f7ff-MuiButtonBase-root-MuiButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  margin-top: -5px !important;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 3px 5px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #5664d2;
}
.text-green {
  color: green !important;
}
.text-blue {
  color: #1e2b56 !important;
}
.text-orange {
  color: orange !important;
}

.text-completed {
  color: green !important;
}
.text-submitted {
  color: #1e2b56 !important;
}
.text-reassessment {
  color: orange !important;
}
.text-pending {
  color: #f4cb69 !important;
}
.overall {
  margin-top: -30px;
}
