.btn-edit {
  background: #080068 !important;
  border-color: #080068 !important;
  color: #ffffff !important;
  margin-right: 5px;
}
.btn-delete {
  background: #f96e6e !important;
  border-color: #f96e6e !important;
  color: #ffffff !important;
  margin-right: 5px;
}
.btn-visibility {
  background: #61ca91;
  border-color: #61ca91;
  color: #ffffff !important;
  margin-right: 5px;
}
.btn-block {
  background: #e3dd3c;
  border-color: #e3dd3c;
  color: #ffffff !important;
}
