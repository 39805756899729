.success-row {
  background-color: #dff0d8 !important;
}
.light-red {
  background-color: #f96e6e5e !important;
}
.light-green {
  background-color: #dff0d8 !important;
}
.light-orange {
  background-color: #f0e6d8 !important;
}
/* .css-weuz2y-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 2.5px 9px !important;
} */
.dots li {
  font-size: 13px !important;
  /* padding-left: 20px; */
  list-style: none;
  margin-top: 0px !important;
  /* text-align: center; */
}
