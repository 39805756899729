.grid2 {
  margin-top: 20px;
}
#CapDetails {
  height: 350px;
  margin-bottom: 20px;
}
.score {
  padding: 44px 0px;
}
.LastUpdated {
  font-size: 12px;
  text-align: right;
  color: grey;
}
.Red {
  color: #f96e6e;
}
.Orange {
  color: orange;
}
.Green {
  color: green;
}
#cap {
  height: 75px;
}
.score h5 {
  margin-top: 10px;
}
#assessment-table {
  padding: 10px;
}
.mdb-datatable table tr {
  border-bottom: 0px solid #dee2e6 !important;
  /* line-height: 36px; */
}
.mdb-datatable table td:nth-child(2) {
  border-left: 1px solid #dee2e6;
}
.mdb-datatable table th:nth-child(2) {
  border-left: 1px solid #dee2e6;
}
