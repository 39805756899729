
.card {
    padding-top: 20px;
    margin: 10px 0 20px 0;
    background-color: rgba(214, 224, 226, 0.2);
    border-top-width: 0;
    border-bottom-width: 2px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .card .card-heading {
    padding: 0 20px;
    margin: 0;
  }
  
  .card .card-heading.simple {
    font-size: 20px;
    font-weight: 300;
    color: #777;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .card .card-heading.image img {
    display: inline-block;
    width: 46px;
    height: 46px;
    margin-right: 15px;
    vertical-align: top;
    border: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  
  .card .card-heading.image .card-heading-header {
    display: inline-block;
    vertical-align: top;
  }
  
  .card .card-heading.image .card-heading-header h3 {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    color: #262626;
  }
  
  .card .card-heading.image .card-heading-header span {
    font-size: 12px;
    color: #999999;
  }
  
  .card .card-body {
    padding: 0 20px;
    margin-top: 20px;
  }
  
  .card .card-media {
    padding: 0 20px;
    margin: 0 -14px;
  }
  
  .card .card-media img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .card .card-actions {
    min-height: 30px;
    padding: 0 20px 20px 20px;
    margin: 20px 0 0 0;
  }
  
  .card .card-comments {
    padding: 20px;
    margin: 0;
    background-color: #f8f8f8;
  }
  
  .card .card-comments .comments-collapse-toggle {
    padding: 0;
    margin: 0 20px 12px 20px;
  }
  
  .card .card-comments .comments-collapse-toggle a,
  .card .card-comments .comments-collapse-toggle span {
    padding-right: 5px;
    overflow: hidden;
    font-size: 12px;
    color: #999;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .card-comments .media-heading {
    font-size: 13px;
    font-weight: bold;
  }
  
  .card.people {
    position: relative;
    display: inline-block;
    width: 170px;
    height: 300px;
    padding-top: 0;
    margin-left: 20px;
    overflow: hidden;
    vertical-align: top;
  }
  
  .card.people:first-child {
    margin-left: 0;
  }
  
  .card.people .card-top {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 170px;
    height: 150px;
    background-color: #ffffff;
  }
  
  .card.people .card-top.green {
    background-color: #53a93f;
  }
  
  .card.people .card-top.blue {
    background-color: #427fed;
  }
  
  .card.people .card-info {
    position: absolute;
    top: 150px;
    display: inline-block;
    width: 100%;
    height: 101px;
    overflow: hidden;
    background: #ffffff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .card.people .card-info .title {
    display: block;
    margin: 8px 14px 0 14px;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    color: #404040;
  }
  
  .card.people .card-info .desc {
    display: block;
    margin: 8px 14px 0 14px;
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    color: #737373;
    text-overflow: ellipsis;
  }
  
  .card.people .card-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    line-height: 29px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .card.hovercard {
    position: relative;
    padding-top: 0;
    overflow: hidden;
    text-align: center;
    background-color: rgba(214, 224, 226, 0.2);
  }
  
  .card.hovercard .cardheader {
    /* background: url("http://lorempixel.com/850/280/nature/4/"); */
    background-color: #f96e6e;
    background-size: cover;
    height: 135px;
  }
  
  .card.hovercard:hover .info{  
    background-color: #f96e6e !important;
  }
  .card.hovercard:hover .info .title{  
   color: #fff !important;
  }
  .card.hovercard:hover .cardheader{
    background-color: #fff;  
  }
  .card.hovercard:hover .cardheader .title1 a{
    color:  #f96e6e !important;
  }
  
  .card.hovercard .avatar {
    position: relative;
    top: -50px;
    margin-bottom: -50px;
  }
  
  .card.hovercard .avatar img {
    width: 60px;
    height: 60px;
    padding: 12px;
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    border: 5px solid white;
    /* box-shadow: 0 0 50px #FD0000; */
    box-shadow: 0 0 22px #6E3838;
  
  
  
  }
  
  .card.hovercard .info {
    padding: 4px 8px 10px;
  }
  
  .card.hovercard .info .title {
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 1;
    color: #f96e6e;
    vertical-align: middle;
  }
  
  .card.hovercard .info .desc {
    overflow: hidden;
    font-size: 12px;
    line-height: 20px;
    color: #737373;
    text-overflow: ellipsis;
  }
  
  .card.hovercard .bottom {
    padding: 0 20px;
    margin-bottom: 17px;
  }
  

  .card.hovercard .avatar {
    position: relative;
    top: -36px;
    margin-bottom: -60px;
  }
  .card.hovercard .info {
    padding: 37px 8px 19px;
    background-color: white !important;
  }
  .title1 {
    font-size: 22px;
    padding-top: 20px;
    font-weight: 700;
    color: white;
  }
  .card{
    border: 5px solid transparent;
  }
  .card:hover{
    position: relative;
    padding-top: 0;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    background-color: rgba(214, 224, 226, 0.2);
    border: 5px solid #f96e6e;
    border-radius:10px;
  }


/* <=========================== cap2 ===========================> */
.card2.hovercard2 .cardheader2 {
    /* background: url("http://lorempixel.com/850/280/nature/4/"); */
    background-color: #fda232;
    background-size: cover;
    height: 135px;
  }
  .card2.hovercard2:hover .info2{  
    background-color: #fda232 !important;
  }
  .card2.hovercard2:hover .cardheader2 .title12 a{
    color:  #fda232 !important;
  }
  .card2.hovercard2 .info2 .title2 {
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 1;
    color: #fda232;
    vertical-align: middle;
  }
  .card2:hover{
    position: relative;
    padding-top: 0;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    background-color: rgba(214, 224, 226, 0.2);
    border: 5px solid #fda232;
    border-radius:10px;
  }
  /* <=========================== cap2 end ===========================> */

  /* <=========================== cap3 ===========================> */

  .card3.hovercard3 .cardheader3 {
    /* background: url("http://lorempixel.com/850/280/nature/4/"); */
    background-color: #0ba360;
    background-size: cover;
    height: 135px;
  }
  .card3.hovercard3:hover .info3{  
    background-color: #0ba360 !important;
  }
  .card3.hovercard3:hover .cardheader3 .title13 a{
    color:  #0ba360 !important;
  }
  .card3.hovercard3 .info3 .title3 {
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 1;
    color: #0ba360;
    vertical-align: middle;
  }
  .card3:hover{
    position: relative;
    padding-top: 0;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    background-color: rgba(214, 224, 226, 0.2);
    border: 5px solid #0ba360;
    border-radius:10px;
  }
  .card.hovercard .avatar img {
    background-color:#f96e6e;
  }
  .card.hovercard .avatar2 img {
    background-color:#fda232;
  }
  .card.hovercard .avatar3 img {
    background-color:#0ba360;
  }

  /* <=========================== cap3end ===========================> */