.feedback-btn {
    color: #f00 !important;
    background-color:#ffffff !important;
    border-color: #ffffff !important;
}
#feedback-btn{
    color: #f00 !important;
    background-color:#ffffff !important;
    border-color: #ffffff !important;
    float: right;
    cursor:pointer;
}