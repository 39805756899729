.Near-Miss {
  background: #acdeef42;
}
.Accident {
  /* background-color: #ffb199; */
  background: #f96e6e42;
}
.Unsafe-Act {
  background: #d59af742;
}
.view-btn {
  color: white !important;
  background-color: #1e2b56 !important;
}
.sales-card {
  height: 100%;
}
.highcharts-title {
  float: left !important;
}
.css-1reg6h5 {
  height: 270px !important ;
  position: relative;
}
.green {
  color: #1abd00;
  border-radius: 15%;
  font-size: 26px !important;
}
.green-fill {
  color: white;
  background-color: #1abd00;
  border-radius: 15%;
  font-size: 20px !important;
}
.red {
  color: #f85757;
  border-radius: 15%;
  font-size: 20px !important;
  border: 2px solid #f85757;
}
.red-fill {
  color: white;
  background-color: #f85757;
  border-radius: 15%;
  font-size: 20px !important;
}
.dark-blue {
  color: #092072;
}
.editBtn {
  width: 61px;
  height: 21px;
  font-size: 14px;
  background: #080068;
  border-radius: 4px;
  padding-bottom: 24px;
}

.bar-graph-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 408px;
}
