.fa {
  color: #fff;
}

.box-top {
  padding: 60px 0px;
  background: #dedede;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.box-top.pending {
  background: linear-gradient(45deg, #f4cb69, #f4cb69);
}

.box-top.new {
  background: linear-gradient(45deg, #f0f0f3, #f0f0f3);
}

.box-top.submitted {
  background: #16275fd1;
}

.box-top.submitted h5 {
  color: white !important;
}

.box-top.reassessment {
  background: linear-gradient(45deg, #fda232, #fda232);
}
.box-top.completed {
  background: #9bdd9b;
}

.box-bottom {
  background: #fff;
  padding: 30px 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.box-bottom .box-text {
  padding: 10px 0px;
}

.box-bottom .instagram-title {
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(45deg, #f2935b, #6f49c0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box-bottom .twitter-title {
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(45deg, #cae1f3, #55aded);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box-bottom .facebook-title {
  font-size: 20px;
  font-weight: 600;
  color: #428bca;
}
.box-column {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin-top: 50px;
  border-radius: 10px;
  overflow: hidden;
}
.box-top h5 {
  color: rgb(0, 0, 0);
}
.catbar {
  width: 90%;
  padding-left: 20px;
  margin-left: 20px;
}
.catper {
  margin-left: -20px;
  font-weight: 600;
  font-size: 15px;
}
