.assessment-add-categroy {
  display: contents;
}
.form-check-input {
  width: 1.5em;
  height: 1.5em;
  margin-top: -6px !important;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.form-check-input:checked {
  background-color: #9bdd9b !important;
  border-color: #9bdd9b !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1e2b56 !important;
}
.card:hover {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  cursor: pointer;
  /* text-align: center; */
  background-color: rgba(214, 224, 226, 0.2);
  border: 0px solid #f96e6e;
  border-radius: 10px;
}
.btn-addquestion-delete {
  background: #f96e6e !important;
  border-color: #f96e6e !important;
  color: #ffffff !important;
}
.company-title {
  text-align: left;
}
.switch-other {
  color: #5d5d5d !important;
}
.governance-switch {
  color: #f99564 !important;
}
.marks-row {
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
}
.question-row {
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-end;
}
.css-ahj2mt-MuiTypography-root {
  margin: 19px 19px 19px 19px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.nav-pills .nav-link {
  border: 2px solid #1e2b56;
  box-sizing: border-box;
  border-radius: 2.5px;
  margin-right: 10px;
  color: #1e2b56;
}
