.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
  }
  
  /* Style the buttons inside the tab */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
  }
  
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #ddd;
  }
  
  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #ccc;
  }
  
  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }
  #leader-board{


    background-color: #fff;
    color: #172b4d;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%);
    overflow: hidden;
margin-top:10px;    
  }
  table td{
    border:none;
  }
  .danger-row,.danger-row:hover{
    background: #F96E6E5E;
    
  }
  table th{
    font-weight:500;
  }
  table td:nth-child(2),td:nth-child(3){
    border-left:1px solid lightgrey;
  }
  table th:nth-child(2),th:nth-child(3){
    border-left:1px solid lightgrey;
  }
  table th{
    border-bottom:0px;
  }
  

/* .card.hovercard .info:hover{
  color:white;
  background-color:#f96e6e ;

} */