.btn-all {
  color: #212e58;
  background-color: #efefef;
  border-color: #efefef;
  margin-right: 10px;
}
.btn-all:hover {
  color: #212e58;
  background-color: #efefef;
  border-color: #efefef;
}
.btn-new {
  color: #25315a;
  background-color: #b9b8b8;
  border-color: #b9b8b8;
  margin-right: 10px;
}
.btn-new:hover {
  color: #25315a;
  background-color: #b9b8b8;
  border-color: #b9b8b8;
}
.btn-pending {
  color: #fff;
  background-color: #f4cc69;
  border-color: #f4cc69;
  margin-right: 10px;
}
.btn-pending:hover {
  color: #fff;
  background-color: #f4cc69;
  border-color: #f4cc69;
}
.btn-submitted {
  color: #fff;
  background-color: #1e2b56;
  border-color: #1e2b56;
  margin-right: 10px;
}
.btn-submitted:hover {
  color: #fff;
  background-color: #1e2b56;
  border-color: #1e2b56;
}
.btn-reassessment {
  color: #fff;
  background-color: #fda232;
  border-color: #fda232;
  margin-right: 10px;
}
.btn-reassessment:hover {
  color: #fff;
  background-color: #fda232;
  border-color: #fda232;
}
.btn-completed {
  color: #fff;
  background-color: #18ad27;
  border-color: #18ad27;
  margin-right: 10px;
}
.btn-completed:hover {
  color: #fff;
  background-color: #18ad27;
  border-color: #18ad27;
}
.categroy-container {
  margin-top: 15px;
  padding-left: 0px;
}
.category-progress-bar {
  background-color: rgb(205, 205, 205) !important;
  color: #4085ec !important;
  width: 90%;
  border-radius: 5px;
  height: 5px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.status-title {
  color: #18ad27;
}
.status {
  margin-bottom: 20px;
  font-size: 13px !important;
}
.category-progress-bar2 {
  background-color: rgb(205, 205, 205) !important;
  color: #4085ec !important;
  width: 75%;
  border-radius: 5px;
  height: 5px !important;
  margin-top: 10px !important;
  margin-bottom: 10px;
}
.category-progress-percentage {
  float: right;
  margin-top: -25px;
  margin-right: 0px;
}
.category-progress-percentage2 {
  float: right;
  margin-top: -25px;
  margin-right: 90px;
}
.link-title {
  font-size: 15px;
  color: #081d29 !important;
}
.btn{
  font-size:13px;
}
