.css-y00m2w-MuiPaper-root-MuiAppBar-root {
  background-color: #ffffff !important;
  color: #1e2b56 !important;
}
.css-llf1rc-MuiPaper-root-MuiDrawer-paper {
  background-color: #1e2b56 !important ;
  color: white !important;
}
.css-ro3fvo-MuiTypography-root {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.334;
  letter-spacing: -0.05px;
  color: #fcf7ed !important;
}
.css-18swgad hr {
  color: white !important;
}
.css-8xplcm-MuiButton-label {
  color: #ffffff;
  padding: 7px 7px 7px 14px;
}
.MuiToolbar-root {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.css-rznra3-MuiInputBase-root-MuiOutlinedInput-root {
  /* width: 351px !important;
  height: 34px; */
  font-size: 15px !important;
  font-weight: 600 !important;
}
.css-8xplcm-MuiButton-label {
  padding: 4px;
  padding-left: 7px;
  padding-right: 7px;
}
.css-8xplcm-MuiButton-label:hover {
  /* background-color: rgba(252, 252, 252, 0.3) !important; */
  /* padding: 5px ; */
}
.feedback-btn {
  color: red !important;
}
/* body {
      zoom: 80% !important;
  } */
ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  /* background-color: #eee; */
}
ul.breadcrumb li {
  display: inline;
  font-size: 15px !important;
}
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: '>\00a0';
}
ul.breadcrumb li a {
  color: #000000;
  text-decoration: none;
}
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: none;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100% !important;
  height: 100% !important;
  background-color: #000;
}
.css-18swgad {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  background-color: #1e2b56;
}
@media (min-width: 1280px) {
  .css-1qnf7ss {
    padding-left: 0px !important ;
  }
}
.css-1sugs6w-MuiFormLabel-root-MuiInputLabel-root {
  color: #6b778c;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  left: 0;
  top: 3px !important;
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}
ul.breadcrumb li a {
  font-size: 15px !important;
  color: #000000;
  text-decoration: none;
}
.h5,
h5 {
  font-size: 15px !important;
}
.css-168k6et-MuiTableCell-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 11px !important;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  /* padding: 16px; */
  color: #172b4d;
}
.css-q92sls-MuiTableCell-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 10px !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  /* padding: 16px; */
  color: #172b4d;
}
.css-19svcu9 {
  padding-top: 12px !important;
}
.css-1p4lczb-MuiGrid-root > .MuiGrid-item {
  padding-top: 5px !important;
  /* padding-bottom: 1px; */
  padding-left: 23px !important;
}
.select {
  width: 100% !important;
  font-size: 10px !important;
}
.css-10zm286-MuiTypography-root {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 15px !important;
  line-height: 1.334;
  letter-spacing: -0.05px;
  display: block;
}
.MuiSelect-selectMenu {
  min-height: 10px !important;
}

.css-8akur5-MuiTypography-root {
  font-size: 10px !important;
}
label {
  font-size: 13px;
}
#date {
  font-size: 13px !important;
  padding: 8px 14px !important;
}
.css-1ao0ee-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 13px !important;
}
#options {
  font-size: 13px !important;
}
p {
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 1.5rem;
}
.modal-title {
  font-size: 15px;
  margin-bottom: 0;
  line-height: 1.5;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1125;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.add-document-close {
  color: red;
  background: white;
  font-size: 25px;
  cursor: pointer;
}
.file-input-icon,
.modal-submit-btn {
  background-color: #1e2b56 !important;
}
.MuiButton-label span {
  font-size: 13px !important;
  padding: 0px !important;
}
.MuiListItem-root span {
  padding: 1px !important;
}
/* .MuiOutlinedInput-root input[type="date"]{
      padding: 0px !important;
  
  } */

table thead th {
  font-size: 15px !important;
}
table tbody tr td {
  font-size: 13px !important;
}
#assessment-card {
  height: 100%;
}
table tbody tr td:nth-child(1) {
  /* text-align/: left !important; */
  /* padding-left: 30px; */
}
.select-assessment {
  width: 200px !important;
  min-height: 10px !important;
}
h2 {
  font-size: 15px !important;
  font-weight: 700;
}
.MuiOutlinedInput-root .MuiSelect-root {
  font-size: 13px !important;
  height: 10px !important;
  padding: 6.5px 32px 18.5px 10px !important;
}

#document-type {
  padding: 26.5px 10px 18.5px 10px !important;
}
#select-state {
  height: 30px !important;
}
#logo {
  width: 90px;
  margin-left: 7px;
  margin-top: -10px;
}
.css-muarbx-MuiButtonBase-root-MuiListItem-root-MuiMenuItem-root {
  font-size: 13px !important;
}
.h4,
h4 {
  font-size: 15px !important;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1102 !important;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1102 !important;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
table.mdb-dataTable thead .sorting,
table.mdb-dataTable thead .sorting_asc,
table.mdb-dataTable thead .sorting_desc,
table.mdb-dataTable thead .sorting_asc_disabled,
table.mdb-dataTable thead .sorting_desc_disabled,
table thead.mdb-dataTable-head .sorting,
table thead.mdb-dataTable-head .sorting_asc,
table thead.mdb-dataTable-head .sorting_desc,
table thead.mdb-dataTable-head .sorting_asc_disabled,
table thead.mdb-dataTable-head .sorting_desc_disabled {
  font-size: 13px !important;
  position: relative;
  cursor: pointer;
}
.table > :not(:last-child) > :last-child > * {
  font-size: 13px !important;
  border-bottom-color: currentColor;
}
table thead tr th,
tbody tr td {
  border-left: 0px solid lightgray !important;
}
table thead tr th:first-child,
tbody tr td:first-child {
  border-left: 0px solid lightgray !important;
}
.table-striped tr:nth-child(even) {
  background-color: #f2f2f2;
}
#download-attachment-feedback {
  text-align: center !important;
}
.vertical-center {
  margin: 0;
  position: relative;
  left: 20%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.pl-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.assess-title {
  font-size: 15px !important;
}
.facebook-title {
  font-size: 15px !important;
}
h3 {
  font-size: 15px !important;
}
.css-1f2kvjf-MuiFormControlLabel-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  margin-left: 0px !important;
  margin-right: 0px !important;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
#logo2 {
  width: 200px;
}
.select2 {
  margin-top: -8px !important;
}
.LastUpdated2 {
  margin-top: 50px;
}
#score-size {
  font-size: 60px;
}
/* changes by karan starts from here */
.view-btn {
  /* margin-left: 10px !important; */
  color: white !important;
  background-color: #1e2b56 !important;
  text-transform: capitalize !important;
}
.tab-title {
  text-transform: capitalize !important;
}
.add-category-btn {
  padding: 5px 20px !important;
}
.add-category-card {
  padding: 0px !important;
}
.add-category-card::-webkit-scrollbar {
  width: 5px !important;
  cursor: pointer;
}
/* Track */
.add-category-card::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.add-category-card::-webkit-scrollbar-thumb {
  background: #3e5294;
  border-radius: 10px;
}

/* Handle on hover */
.add-category-card::-webkit-scrollbar-thumb:hover {
  background: #1e2b56;
}
.add-category {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.add-category-card .list-group .list-group-item {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border: 1px solid lightgray !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-right: 20px !important;
}
.view-questions tr td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 1px !important;
  font-size: 15px !important;
}
.users-tab {
  border: 2px solid #1e2b56 !important;
  box-sizing: border-box !important;
  border-radius: 2.5px !important;
}
/* changes by karan ends here */
.feedback-title {
  font-size: 15px;
}
.feedback-header {
  padding: 0.2rem 1rem;
}
u {
  cursor: pointer;
  color: #1e2b56;
  font-weight: 700;
  font-size: 15px;
}
.MuiTableCell-root {
  font-size: 13px !important;
}
.text-center {
  text-align: center !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1e2b56;
  border-color: #1e2b56;
}
.table-foot {
  display: none !important;
}
/* .assessment-table-contractor{
    height: 
  } */
#feedback-btn {
  color: #f00 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  float: right;
  cursor: pointer;
  font-size: 20px;
  margin-right: -15px;
}
.upload-btn {
  text-transform: capitalize !important;
}
.table-title,
.table-title4 {
  font-size: 15px;
  font-weight: 500;
}
/* .coloumn-min-height {
    border-right:1px solid lightgrey
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 38px !important;
  } */
.icon-height {
  height: 21px !important;
}
.btn-outline-success,
.btn-outline-danger,
.chat-btn,
.attach-btn {
  height: 30px !important;
  padding: 0px 8px !important;
}
.border-right {
  border-right: 1px solid lightgray;
}
.msg-date {
  text-align: center;
  background-color: #1e2b56;
  color: white;
  border-radius: 8px;
  padding: 3px;
  font-size: 15px;
  position: relative;
  left: 38%;
  width: 116px;
}
.response-file {
  -ms-transform: rotate(20deg); /* IE 9 */
  transform: rotate(20deg);
}
.assessment-button {
  text-transform: capitalize !important;
}
.score-text {
  height: 30px !important;
  margin-bottom: 0px !important;
  width: 65px !important;
}
.dropddown-selection {
  position: absolute;
  font-weight: 600;
  margin-top: 0px;
  color: #081d29;
}
.view-btn2 {
  font-size: 13px !important;
  padding: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.view-btn {
  text-transform: capitalize !important;
}
.hover {
  cursor: pointer;
}
.LastUpdated {
  font-size: 13px !important;
  text-align: right;
  color: grey;
}
.total-score {
  font-size: 25px !important;
}
table tr th,
table tr td {
  text-align: center !important;
}
.modal-submit-btn {
  text-transform: capitalize !important;
}
#login-card {
  padding: 30px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.forgot-password {
  font-size: 20px !important;
  color: #6b778c;
}
.login-button {
  background-color: #1d2c56 !important;
  text-transform: capitalize !important;
}
.learning-buttons {
  float: left;
  margin-top: -4px !important;
  height: 50px !important;
  background-color: #1e2b56 !important;
  margin-left: 10px !important;
}
/* #document-type{
    height: 30px !important;
  } */

.css-1xeod2-MuiLinearProgress-bar1 {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  -webkit-transition: -webkit-transform 0.4s linear;
  transition: transform 0.4s linear;
  transform-origin: left;
  background: linear-gradient(90deg, #4481eb 0%, #04befe 100%) !important;
}
