/* default background-color #e1e2e7 */
.checklist-progress-bar {
  background-color: rgb(205, 205, 205) !important;
  color: #4085ec !important;
  width: 75%;
  border-radius: 5px;
  height: 5px !important;
  margin-top: 10px !important;
  margin-bottom: 10px;
}
.checklist-progress-percentage {
  float: right;
  margin-top: -25px;
  margin-right: 90px;
  margin-bottom: 0px !important;
}
.checklist-container {
  margin-top: 15px;
  padding-left: 0px;
}
.table-title {
  text-align: center;
  /* border-right: 1px solid black; */
}
.wrong {
  font-size: 2rem !important;
  color: #ff7373 !important;
}
.correct {
  font-size: 2rem !important;
}
/* .btn-outline-success {
  margin-right: 10px;
  margin-left: -20px;
  margin-top: 10px;
} */
/* .btn-outline-danger {
  margin-top: 10px;
} */
.sub-title {
  /* margin-top: 22px; */
  text-align: left;
}
.btn-save {
  color: #091e2a;
  background-color: #ffffff;
  border-color: #ffffff;
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 800px;
}
.btn-submit {
  color: #ffffff;
  background-color: #1e2b56;
  border-color: #1e2b56;
  margin-top: 20px;
  align-content: center;
}
.btn-submit:hover {
  color: #ffffff;
  background-color: #1e2b56;
  border-color: #1e2b56;
}
.table-title4 {
  text-align: center;
}
/* For Modal S */
.modal-submit-btn {
  color: #ffffff;
  background-color: #1e2b56;
  border-color: #1e2b56;
}
.modal-submit-btn:hover {
  color: #ffffff;
  background-color: #1e2b56;
  border-color: #1e2b56;
}
/* For Modal E */
.chat-btn {
  color: #ffffff;
  background-color: #1e2b56;
  border-color: #1e2b56;
  margin-right: 10px !important;
}
.chat-btn:hover {
  color: #ffffff;
  background-color: #1e2b56;
  border-color: #1e2b56;
}
.attach-btn {
  color: #ffffff;
  background-color: #1e2b56;
  border-color: #1e2b56;
}
.attach-btn:hover {
  color: #ffffff;
  background-color: #1e2b56;
  border-color: #1e2b56;
}
#file-input {
  display: none !important;
}
.file-name {
  float: right !important;
}
.file {
  cursor: pointer !important;
}
.response-file {
  /* color: #bebebe !important; */
  cursor: pointer !important;
}
.msg_card_body {
  overflow-y: auto;
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  background-color: #e3e3e3 !important;
  padding: 10px;
  position: relative;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #e3e3e3 !important;
  padding: 10px;
  position: relative;
}
.msg_time {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: #32434c !important;
  font-size: 10px;
}
.msg_time_send {
  position: absolute;
  right: 0;
  bottom: -15px;
  color: #32434c !important;
  font-size: 10px;
}
.msg-date {
  text-align: center;
}
.sub-title h5{
  font-size:15px;
}
.css-d2rydc-MuiInputBase-input-MuiOutlinedInput-input{

  font-size: 15px !important;
}
