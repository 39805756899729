.splashscreen {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.5);
}

.primary-green{
  color : #18ad27
}

.primary-red{
  color : #FF7373
}
